import React, { useEffect, useState } from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import HeroSection from "../../components/KFLiveSeries/HeroSection/heroSection";
import Reviews from "../../components/KFLiveSeries/Reviews/reviews";

import "./styles.scss";
import ContactUsPopup from "../../components/ContactUsPopup";
import RegistrationSection from "../../components/KFLiveSeries/RegistrationSection";
import KraftshalaExperience from "../../components/KFLiveSeries/KraftshalaExperience";
import { CONVEX_API_URL } from "../../../config/config";

const KraftshalaLiveSeries = () => {

  const [activeEvents, setActiveEvents] = useState([]);

  useEffect(() => {
    fetchActiveEvents();
  }, []);

  const fetchActiveEvents = async() => {
    try {
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/latest-events`;
      
      const res = await fetch(url);
      const data = await res.json();

      if(res.ok && res.status === 200)
        setActiveEvents(data);     

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Seo
        noIndex = {true}
      />
      <ContactUsPopup />
      <Layout>
        <div className="kraftshala-live-series-page">
          <div className="top-decoration">
            <div className="yellow-bg">
              Learn sales and marketing from India’s premium leaders, all for Free in Kraftshala LIVE.
            </div>
            <div className="triangle-1"></div>
            <div className="triangle-2"></div>
          </div>
          <HeroSection activeEvents={activeEvents}/>
          <RegistrationSection activeEvents={activeEvents}/>
          {/* <PastSessions /> */}
          {/* <WatchSession /> */}
          <Reviews />
          {/* <Information /> */}
          <KraftshalaExperience />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default KraftshalaLiveSeries;
