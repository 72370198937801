import moment from "moment";
import React from "react";
import Divider from "@mui/material/Divider";
import Card from "./Card";

import "./styles.scss";

const RegistrationSection = ({ activeEvents }) => {
  return (
    <section className="ks-live-registration-section">
      <div className="header-main">
        <div className="upcoming-session-title">
          Upcoming Free <span className="highlight-yl">Kraftshala LIVE</span>
        </div>
        <div className="sub-title">Learn . Upskill . Repeat</div>
      </div>
      <Card activeEvents={activeEvents}/>
    </section>
  );
};

const RegistrationCard = ({ event }) => {
  return (
    <div className="ks-live-registration-card">
      <div className="session-banner">
        <img
          src={event?.banner}
          alt={`${event?.title} banner image`}
          loading="lazy"
        />
      </div>
      <div className="session-details-main">
        <div className="session-meta">
          <div>
            <b>Webinar: </b> {event?.title ?? "--"}
          </div>
          <div>
            <b>Time:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("LT")
              : "--"}
          </div>
          <div>
            <b>Date:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("LL")
              : "--"}
          </div>
          <div>
            <b>Speaker:</b> {event?.speakers}
          </div>
        </div>
        <hr style={{ marginBottom: "unset" }} />
        <div className="session-about-info">{event?.description}</div>
        <button
          className="register-btn"
          onClick={() =>
            window.open(`/kraftshala-live/register/${event?.id}`, "_top")
          }
        >
          Register Now
        </button>
      </div>
    </div>
  );
};

const NewRegCard = ({ event }) => {
  return (
    <div className="ks-live-registration-card">
      <div className="session-banner">
        <img
          src={event?.banner}
          alt={`${event?.title} banner image`}
          loading="lazy"
        />
      </div>
      <div className="session-details-main">
        <div className="session-meta">
          {/* <div>
              <b>Webinar: </b> {upcomingEvent?.title ?? "--"}
          </div> */}
          <div>
            <b>Date:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("ll")
              : "--"}
          </div>
          <div>
            <b>Day:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("dddd")
              : "--"}
          </div>
          <div>
            <b>Time:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("LT")
              : "--"}
          </div>
        </div>
        <Divider
          orientation="vertical"
          sx={{ width: "1px !important", height: "6em" }}
        />
        <div className="session-about-info">{event?.description}</div>
      </div>
      <button
        className="register-btn"
        onClick={() =>
          window.open(`/kraftshala-live/register/${event?.id}`, "_top")
        }
      >
        Register Now
      </button>
    </div>
  );
};

export default RegistrationSection;
